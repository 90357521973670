import {React , useState} from 'react';
import './macroComponents.scss';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { updateProfile } from '../../services/exhibitorService';

export default function AboutUs(props) {
  const { isAdmin } = props;
  const [edit, setEdit] = useState(false);
  const [content, changeContent] = useState(props.content);
  const [link,changeLink] = useState(props.brochureLink);

  const editClick = () => {
    setEdit(!edit);
  };
  function saveChanges(){
    updateProfile({
      about_us:content,
      brochure_link:link
    });
  };
  function _changeLink(event){
    changeLink(event.target.value);
 }
  function _changeContent(event){
    changeContent(event.target.value);
 }
 

  return (
    <>
      <div className="aboutUsHeading">
        <span>About Us</span>
        { isAdmin && <Button type="button" className="edit" onClick={editClick}> {edit ? 'View Mode' : 'Edit'} </Button>  }
      </div>
      <div className="aboutUsContent">
        {
          edit ?
          (
            <div className='editbox'>
              <div className="" >
                Edit description
                <TextField 
                multiline
                rows={6}
                maxRows={8}
                 id="filled-basic" label="About us"   className='input-field'
                link
                onChange={_changeContent}
                value={content} />
              </div>
              <div className="">
                  Brochure link
                  <TextField 
                  id="filled-basic" 
                  label="link" 
                  onChange={_changeLink}
                  value={link}
                  className='input-field' />

              </div> 

              <Button type="button" className="edit" onClick={saveChanges}> Save Changes </Button>
            </div>
          )
          :
          ( 
            <>
            <div className="c" >{props.content}</div>
            <div className="brochure">
              <div className="brochureContent"><a href={props.brochureLink} target='_blank'> Download Our Brochure </a></div>
              <button
                className="submitBrochure"
                onClick={() => window.open(props.brochureLink, '_blank')}
              >
                Download
              </button>
            </div> 
          </>)
        }
       
      </div>
    </>
  );
}
