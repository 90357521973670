function initURLFetch(){
    let url = new URL(window.location.href);
    let jwt = url.searchParams.get('jwt');
    let exhibitor_id = url.searchParams.get('exhibitor_id');
    let selfExibitorId = url.searchParams.get('exhibitorId');
    let selfVisitorId = url.searchParams.get('visitorId');
    let exhibitionId = url.searchParams.get('exhibitionId');
    if (!!jwt) {
      localStorage.setItem('jwt', jwt);
    }
    if (!!exhibitor_id) {
        setProfileId(exhibitor_id)
    }
    if(!!selfExibitorId) {
        localStorage.setItem('selfExhibitorId', selfExibitorId); 
    }
    if(!!selfVisitorId) {
        localStorage.setItem('selfVisitorId', selfVisitorId); 
    }
    if(!!exhibitionId) {
        localStorage.setItem('exhibitionId', exhibitionId); 
    }
  }
function setProfileId(exhibitor_id){
    localStorage.setItem('exhibitor_id', exhibitor_id);
    let exhibitors = JSON.parse(localStorage.getItem('exhibitors') || `{}`);
    if(!exhibitors[exhibitor_id]){
        exhibitors[exhibitor_id] = 0;
        localStorage.setItem('exhibitors', JSON.stringify(exhibitors));
    }
}

export { initURLFetch };