import profileIcon from './/../../assets/icons/restrurantLogo.png';
import './macroComponents.scss';
import locationGreen from './../../assets/icons/locationGreen.png';
import urlIcon from './../../assets/icons/web.png';
import React from 'react';
export default function ProfileHeader(props) {
  const { tags } = props;
  return (
    <div className="flex detailsContainer">
      <div className="profileImage">
        <img width="80" height="80" alt="profile Icon" src={profileIcon} />
      </div>
      <div className="profileHeaderContent">
        <div id="stallNo">Stall Number: {props.shopId}</div>
        <div id="profileName">{props.name}</div>
        <div id="profileLocation">
          {' '}
          <img alt="location Green" src={locationGreen} />{' '}
          {`${props.contact}`}
        </div>
        <div id="profileUrl">
          {' '}
          <a   href={`mailto:${props.email}`}>
            <img alt="email"  width="16px" height="16px" src={urlIcon} /> Email Us{' '}
          </a>{' '}
        </div>
        {tags && (
          <div className="profileTag">
            {tags.map((item, index) => {
              return (
                <div key={index} className={`tagitem tag-${index}`}>
                  {item}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
