import expoLogo from './../../assets/icons/exposyncLogo.png'
import frame1 from './../../assets/icons/headerFrame1.png';
import frame2 from './../../assets/icons/headerFrame2.png';
export default function DashboardHeader(props){
    const { number } = props;
    function redirect(){
        const myExhibitorId = localStorage.getItem('selfExhibitorId');
        window.location.href = `/profile?exhibitor_id=${myExhibitorId}`;
    }
    function openDocs(){
        window.open('https://docs.google.com/document/d/1ZUG2sMFpRDcQqgLQqD0SMbHChBagvKAzinPt9cgtHpM/edit?usp=sharing','_blank');
    }
    return(
 <>
    <div className="headerLeftImage">
        <img width={200} height={50} alt="logo" src={expoLogo}/>
    </div>
    <div style={{ display: 'flex'}} className='headerRightImage'>
        
        <div style={{   
             background: '#31323e', height: '55px',margin: '10px', 'borderRadius': '10px', 
             padding: '0px 10px',     'textAlign': 'center' , 'lineHeight': '1.5rem' }}> 
             Total cards recieved <br /> <span className='green'>{number}</span>
        </div>
        <img onClick={redirect} className='btns' width={250} height={80} alt="frame1" src={frame1}/>
        <img onClick={openDocs} className='btns' width={250} height={80} alt="frame2" src={frame2}/>
    </div>
    </>
    )
}