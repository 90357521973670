import AboutUs from '../../components/macroCompnents/AboutUs';
import ProfileHeader from '../../components/macroCompnents/ProfileHeader';
import Layout from '../../layout/Layout';
import './profile.scss';
// import facilitiesIcon from './../../assets/icons/facilities.png';

// import FacilitiesGroup from '../../components/macroCompnents/FacilitiesGroup';
// import profileIcon from './../../assets/icons/restrurantLogo.png';
import { useEffect, useState } from 'react';
// import shareIcon from './../../assets/icons/share3.png';
import addIcom from './../../assets/icons/add2.png';
// import profileIconLeft from './../../assets/icons/profileArrowLeft.png';
// import profileIconRight from './../../assets/icons/profileArrowRight.png';
import React from 'react';
import RegisterVisitorModal from '../../components/microComponents/RegisterVisitorModal';
import { getCards, createCard, exchangeCard,unsharedExhibitors, removeExhibitorFromList } from '../../services/cardService';
import { getExhibitorDetails } from '../../services/exhibitorService';
export default function Profile() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const isLoggedIn = !!localStorage.getItem('jwt');
  const unsharedExhibitorIds = unsharedExhibitors();
  let url = new URL(window.location.href);
  const exhibitorId = url.searchParams.get('exhibitor_id') || localStorage.getItem('exhibitor_id');
  const selfExhibitorId = localStorage.getItem('selfExhibitorId');
  const isAdmin = selfExhibitorId===exhibitorId;
  const [showModal, setShowModal] = useState(!isLoggedIn);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  useEffect(() => {
    if(!!exhibitorId){
      getExhibitorDetails(exhibitorId).then((res)=>{
        setData(res.data);
        setLoading(false);
      }).catch((err)=>{
        console.log('err',err)
      })
    }
  
  }, []); // Empty dependency array ensures useEffect runs once, equivalent to componentDidMount

  if (loading) {
    return <div>Loading...</div>;
  }


  if(!!isLoggedIn){
    // check if the user has cards already
    getCards().then((res) =>{
      if(!res.data.cards || res.data.cards?.length===0){
        // if not then create a new card
        let user = JSON.parse(localStorage.getItem('user') || {});
        let card =  {
          "card_name": "default",
          "company":user.company_name || '',
          "first_name":user.name.split(' ')[0],
          "last_name": user.name.split(' ').slice(1).join(' '),
          "company_details": {},
          "email": user.email,
          "mobile": user.contact,
      };
        createCard(card).then((res) =>{
          card.id = res.data.card;
          localStorage.setItem('myCard', JSON.stringify(card));
          if(unsharedExhibitorIds.length>0){
            shareCard(unsharedExhibitorIds);
          }
        });
      }
      else{
        localStorage.setItem('myCard', JSON.stringify(res.data.cards[0]));
        if(unsharedExhibitorIds.length>0){
          shareCard(unsharedExhibitorIds);
        }
      }
    });
  }
  function addFacilities() {
    if (data.facilities.length <= 5) {
      let facility = {
        name: '',
        content: '',
      };
      const copyData = { ...data };
      copyData.facilities.push(facility);
      copyData['activeFacility'] = data.facilities.length - 1;
      setData(copyData);
    }
  }
  function setPrev() {
    if (activePhotoIndex > 0) {
      setActivePhotoIndex(activePhotoIndex - 1);
    }
  }
  function setNext() {
    if (activePhotoIndex + 1 < data.photos.length) {
      setActivePhotoIndex(activePhotoIndex + 1);
    }
  }
  function shareCard(exhibitorIds=[]) {
    let mycard = JSON.parse(localStorage.getItem('myCard') || '{}');
    if(!mycard.id) return;
    exhibitorIds.forEach(exhibitorId => {
      exchangeCard(mycard.id,exhibitorId).then((res) => {
        removeExhibitorFromList(exhibitorId);
      }).catch((err)=>console.log('err',err));
    })

  }

  return showModal ? (
    <Layout>
      <div>
        <RegisterVisitorModal
          showModal={showModal}
          setShowModal={setShowModal}
        ></RegisterVisitorModal>
      </div>
    </Layout>
  ) : (
    <Layout>
      <div>
        <ProfileHeader
          shopId={data.stall_no}
          img={data.logo}
          name={data.name}
          country={data.country}
          state={data.state}
          city={data.city}
          email={data.email}
          contact={data.contact}
          isAdmin={isAdmin}
          // tags={data.tags}
        />
      </div>
      <div className="aboutUs">
        <AboutUs 
          content={data.about_us} 
          brochureLink={data.brochure}
          isAdmin={isAdmin}
          />
      </div>
      {/* <div className="facilities">
        <div className="facilitiesHeading">
          <img alt="facilities" src={facilitiesIcon} /> Facilities Offered
          {isAdmin && (
            <span>
              <button onClick={addFacilities} className="submitBrochure">
                Add More
              </button>{' '}
            </span>
          )}
        </div>
        <FacilitiesGroup
          isAdmin={isAdmin}
          activeFacility={data.activeFacility ? data.activeFacility : 0}
          facilities={data.facilities}
        />
      </div> */}
      {/* <div className="photoSection">
        <div className="photoHeading">
          <img alt="facilities Icon" src={facilitiesIcon} /> Photo Section
          {isAdmin && (
            <span>
              <button onClick={addPhotoPopup} className="submitBrochure">
                Add More
              </button>{' '}
            </span>
          )}
        </div>
        <div>
          <div className="activeImageContent">
            <img
              alt="profile Icon"
              width="100"
              height="100"
              src={profileIcon}
            />
            <div className="photoCardHeading" contentEditable={data.isAdmin}>
              {data.photos[activePhotoIndex].heading}
            </div>
            <div className="photoCardSubHeading" contentEditable={data.isAdmin}>
              {data.photos[activePhotoIndex].subHeading}
            </div>
          </div>
          <div className="scrollpic">
            <div onClick={setPrev}>
              <img alt="prev" src={profileIconLeft} />
            </div>

            {data.photos.map((item, index) => {
              return (
                <img
                  key={index}
                  id={index}
                  alt="photos"
                  className={`${
                    index === activePhotoIndex ? 'photoactive' : ''
                  }`}
                  width="40"
                  height="40"
                  src={profileIcon}
                />
              );
            })}

            <div onClick={setNext}>
              <img alt="next" src={profileIconRight} />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="buttonBottom">
        <div className="flex buttonsProfile">
          <img alt="icon" src={addIcom} /> Add to Visited
        </div>
        <div className="flex buttonsProfile">
          <img alt="share" src={shareIcon} /> Share Card
        </div>
      </div> */}
    </Layout>
  );
}
