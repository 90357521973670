import {
  Button,
  Modal,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import Tips from '../../components/macroCompnents/tips';
import React, {  useState } from 'react';
import { register } from '../../services/register';
import CountryCodeSelect from '../macroCompnents/CountryCodeSelect';
import { useNavigate } from 'react-router-dom';
import './RegisterVisitorModal.scss';

const RegisterVisitorModal = (props) => {
  const { showModal, setShowModal } = props;
  const [email, setEmail] = useState('example@company.com');
  const [contact, setContact] = useState('1234567890');
  const [cname, setCname] = useState('');
  const [name, setName] = useState('');
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const phoneRegex = /^[0-9]{10}$/;
  const navigate = useNavigate();

  const onSubmitClick = () => {
    if (emailRegex.test(email) && phoneRegex.test(contact)) {
      register(email, contact, name);
      localStorage.setItem(
        'user',
        JSON.stringify({
          name,
          contact,
          email,
          company_name: cname,
        })
      );
      setShowModal(!showModal);
    }
  };

  return (
    <Modal open={showModal}>
      <section id="background">
        <Paper sx={{ backgroundColor: '#fff0' , overflow: 'auto', height: '100vh'}}>
        <div className='fill-once-header'>
              <span>Fill <span className='green'>Once</span>,</span> <span>scan <span className='green'>everywhere</span></span>
              <div className="subtext">
                  What will Be Your Benefits?
                   by just Scanning, you can download any company's Brochure and can also share your Digital Card with them..
              </div>
        </div>
        <Tips tips="Fill the form below so that we can create your personal digital cards and share with exhibitors" />

          <Stack
            display="flex"
            justifyContent="center"
            flexDirection="column"
            padding={4}
            spacing={5}
            color='white'
          >
            <TextField
              className='text'
              variant="outlined"
              required
              label="email id"
              placeholder="example@company.com"
              helperText="email id must be valid"
              error={!emailRegex.test(email)}
              sx={{
                width: 300,
                alignSelf: 'center',
              }}
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
            <CountryCodeSelect />

            <TextField
              required
              label="contact"
              className='text'
              error={!phoneRegex.test(contact)}
              placeholder="your phone number"
              sx={{
                width: 300,
                alignSelf: 'center',
              }}
              onChange={(e) => setContact(e.target.value)}
              helperText="should be a 10 digit number"
            ></TextField>
            <TextField
              required
              className='text'
              label="name"
              sx={{
                width: 300,
                alignSelf: 'center',
              }}
              onChange={(e) => setName(e.target.value)}
              helperText="Cannot be blank"
            ></TextField>
            <TextField
              label="company name"
              className='text'
              sx={{
                width: 300,
                alignSelf: 'center',
              }}
              onChange={(e) => setCname(e.target.value)}
              helperText="Optional"
            ></TextField>
            <Button
              variant="contained"
              sx={{
                width: 300,
                alignSelf: 'center',
                backgroundColor:'#15A150'
              }}
              color="inherit"
              onClick={() => {
                onSubmitClick();
                navigate(`/profile?id=1`);
              }}
            >
              submit
            </Button>
          </Stack>
        </Paper>
      </section>
    </Modal>
  );
};

export default RegisterVisitorModal;
