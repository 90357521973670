import CardRecieved from '../../components/macroCompnents/CardRecieved';
import CountSuppCus from '../../components/macroCompnents/CountSuppCus';
import DashboardHeader from '../../components/macroCompnents/DashboardHeader';
import IndAnalytics from '../../components/macroCompnents/IndAnalytics';
import mailIcon from './../../assets/icons/mailbig.png';
import downloadIcon from './../../assets/icons/downloadbtn.png';
import whatsappIcon from './../../assets/icons/whatsapp.png'
import searchIcon from './../../assets/icons/searchBlack.png'
import './dashboard.scss';
import Tabulator from '../../components/microComponents/tabulator';
import 'react-tabulator/lib/styles.css';
import allSelected from './../../assets/icons/allSelected.png';
import allUnselected from './../../assets/icons/allUnselected.png'
import { useState,useRef, useEffect } from 'react';
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
import selected from '././../../assets/icons/selected.png';
import unselected from './../../assets/icons/unselected.png';
import { ReactTabulator } from 'react-tabulator';
import { getDashboardDetails,sendEmail } from '../../services/exhibitorService';
import Popup from "../../components/macroCompnents/popup/Popup";
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { getToken } from '../../services/constants';
export default function Dashboard(){
    const [select,setSelect]=useState(false);
    const [waMsg,setWAmsg]=useState(localStorage.getItem( 'cache_wamsg') || '');
    const [mailBody,setMailBody]=useState(localStorage.getItem( 'cache_mailBody') || '');
    const [mailSubject,setMailSubject]=useState(localStorage.getItem( 'cache_mailSub') || '');
    const [msgError,setMsgError]=useState('');
    const [msgInfo,setMsgInfo]=useState('');
    const [sendWhatsapp, setSendWhatsapp] = useState(false);
    const [sendMail, setSendMail] = useState(false);
    const myExhibitorId = localStorage.getItem('selfExhibitorId');
    const [ number, updateNumber ]  = useState(0);
    const [data, setData] = useState(null);
    
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        if(!!myExhibitorId){
          getDashboardDetails().then((res)=>{
            let cards = res.data.cards;
            let uniqueCards = Array.from(new Set(cards.map(card => card.id))).map(
              id => cards.find(card => card.id === id)
            );
            
            setData(uniqueCards);
            updateNumber(uniqueCards.length);
            setLoading(false);
            setTimeout(() =>{
              handleAllSelectToggel();
            },1000)
          }).catch((err)=>{
            console.log('err',err)
          })
        }
      
      }, []);

    function handelSearch(event){

    }
    function handleAllSelectToggel(){
      setSelect(!select);
      if(!select){
        workloadsTableRef.current.selectRow();
      }
      else{
        workloadsTableRef.current.deselectRow();
      }
      workloadsTableRef.current.getRows().forEach((row)=>{
        row.update({selected : !row.getData().selected});
      })
    }
    const workloadsTableRef = useRef(null);
   
    function download(){
        workloadsTableRef.current.download("csv", "Visitors.csv");
    }
    const onRowClick = (e, row)=>{
      row.update({selected : !row.getData().selected});
    }

    function handleSendWhatsapp() {
      setSendWhatsapp(true);
    }
  
    function handelClose() {
      setSendWhatsapp(false);
      setSendMail(false);
      setMsgError('');
      setMsgInfo('');
    }
    function handleSendMail() {
      setSendMail(true);
    }
    function _changeWamsg(event){
      setWAmsg(event.target.value);
      localStorage.setItem('cache_wamsg', event.target.value);
    }
    function _changeMailSubject(event){
      setMailSubject(event.target.value);
      localStorage.setItem('cache_mailSub', event.target.value);
    }
    function _changeMailBody(event){
      setMailBody(event.target.value);
      localStorage.setItem('cache_mailBody', event.target.value);
    }
    function sendMsg(){
      if(!!sendWhatsapp){
        // check if extension is installed
        if(!window.isExInstalled){
          setMsgError('Please try again after installing the extension and refresh. Dont worry! your message is saved with us.');
          window.open('https://chromewebstore.google.com/detail/exposync-whatsapp-message/bocdbepfngpkinmaamanfkelblnopoje?hl=en','_blank');
          return;
        }
        let phoneNumbersAndMessages = []; 
        // With country code { number: '0987654112', message: 'Hit' },// Without country code (defaults to 91)
        workloadsTableRef.current.getRows().forEach((row)=>{
          if(row.getData().selected){
            const mobile = '91' + row.getData().mobile;
            phoneNumbersAndMessages.push({
              number: mobile,
              message: waMsg
            });
          }
        });
        setMsgInfo('Opening Whatsapp Web to start sending messages, if not logged in, please log in to your Whatsapp Web and try again. You can safety close this popup though.');

        // Schedule message(s)
        if(window.senddata) window.senddata({ numbers: phoneNumbersAndMessages });
        setTimeout (() => {
          // Open whatsapp web (Automatically starts sending messages)
          window.open(" https://web.whatsapp.com" )
        }, 2000);

      }
      else{
        if(!mailSubject ||!mailBody){
          setMsgError('Please fill all the fields');
          return;
        }
        setMsgError('');
        sendEmail({
            "subject": mailSubject,
            "text": mailBody
        }).then((res) => {
          setMsgInfo('Mails sent sucessfully.');
          setTimeout(() => handelClose, 5000);
        }).catch((err,res) => {
          const errorMsg = err.response?.data?.message || '';
          if(errorMsg.includes('Login')){
            setMsgError("Please authenticate the mail service and then try again. Don't worry! your email is saved with us.");
          }
          else{
            setMsgError("Please authenticate the mail service and then try again. Don't worry! your email is saved with us.");
          }
          window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=https://api-staging.exposync.in/v1/exhibitor/googleauth&prompt=consent&response_type=code&client_id=456015606846-3fv2ndekb5nf9qtbga7e7dt3k4hud5oj.apps.googleusercontent.com&scope=https://mail.google.com&access_type=offline&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow&state=${getToken()}`
          console.log('err',err.response.data?.message);
        });
      }
    }
    let columns=[
            {
                title:'', 
                vertAlign:'middle',
                cssClass:"nobg", 
                field:"selected", 
                editor:true, 
                width:10,
                headerSort:false ,
                formatter:function(cell,formatterParams,onRender){
                  return `<img class="checkbox"  width="20" height="20" alt="fs" src="${unselected}"/>`
                  if(cell.getValue()){
                    return `<img class="checkbox"  width="20" height="20" alt="fs" src="${selected}"/>`
                  }
                  else{
                    return `<img class="checkbox" width="20" height="20" alt="asd" src="${unselected}"/>`
                  }
              },
        },
        { 
            title: "Name", headerSort:false, field: "first_name", width: 350 ,
            formatter:function(cell,formatterParams,onRender){
                let cellData=cell.getData();
                return `<div><div class="tabilatorTitleHead">${cellData.first_name} ${cellData.last_name}</div>`;
            },
        },
        {title:"Last Name", field:"last_name", visible:false},
        { 
            title: "Company", 
            headerSort:false, 
            field: "company", 
            width: 350 ,

        },
        { 
            title: "Email", 
            headerSort:false, 
            field: "email", 
            width: 350 ,
        },
        { 
            title: "Mobile Number",
            width:350, 
            headerSort:false, 
            field: "mobile", 
            hozAlign: "left",  
        },
      ];
      if(!!loading) return <div>Loading...</div>
  return (
    <div className="dashboardPage">
              {
              sendWhatsapp || sendMail ? (
                <div className="popup"> 
                  <Popup handelClose={handelClose}>
                      <div className='popup-container'>
                        <div className="popup-header">
                            {sendWhatsapp ? 'Send Whatsapp' : 'Send Mail'}
                        </div>
                        <div className="popup-content">
                          <div className='mail-parent'>
                            <div className='errorText'>{msgError}</div>
                            <div className='infoText'>{msgError ? '' : msgInfo}</div>
                            { sendWhatsapp ? (
                              <TextField 
                                className='text'
                                multiline
                                rows={6}
                                maxRows={8}
                                id="filled-basic" label="Write Message" 
                                onChange={_changeWamsg}
                                value={waMsg}
                            ></TextField>
                            ) : (
                              <>
                                <TextField 
                                  className='text'
                                  multiline
                                  rows={1}
                                  maxRows={2}
                                  id="filled-basic" label="Write Subject" 
                                  onChange={_changeMailSubject}
                                  value={mailSubject}
                                ></TextField>
                                <TextField 
                                  className='text'
                                  multiline
                                  rows={6}
                                  maxRows={8}
                                  id="filled-basic" label="Write Body" 
                                  onChange={_changeMailBody}
                                  value={mailBody}
                                ></TextField>
                                </>
                            )}
                          </div>

                        </div>
                          <Button className='sendbtn' onClick={sendMsg}> SEND </Button>
                        </div>
                        
                  </Popup>
                </div>

            ) : null
            }
        <div className='header'>
            <DashboardHeader number={number}/>   
        </div>
        <div className='dashboardSearch'>
            <div className='' >
                <img height={30} alt="search" src={searchIcon}/>
                <input
                className="dashboardSearchField bg-transparent"
                placeholder="Search"
                onChange={handelSearch}
              />

            </div>
            <div className='action-btns'>
                <img className='downloadbtn btns' alt="download" src={downloadIcon} onClick={download} />
                <img className="btns" height={50} onClick={handleSendMail} alt="mail" src={mailIcon} />
                <img className="btns" height={50} onClick={handleSendWhatsapp}alt="whatsapp" src={whatsappIcon} />
            </div>

        </div>
        <div className='tabulator-react'>
            <img onClick={handleAllSelectToggel} width={20} src={select?allSelected:allUnselected} className='tableCheckbox'/>
            <ReactTabulator id="example-table"
            onRef={(r) => (workloadsTableRef.current = r.current)}
             data={data}
           columns={columns}
           options={  {
              selectable:true,
              downloadRowRange:"selected"
          }
          }
             layout="fitDataStretch"
             events={{
              rowClick : onRowClick
              }}
           />
        </div>
    </div>
  )
}