import { baseUrl, getToken } from './constants';
import axios from 'axios';

function getExhibitorDetails(id){
    return axios.get(
        baseUrl +`/v1/exhibitor/${id}`
      );
};
function getDashboardDetails(){
    return axios.get(
        baseUrl +`/v1/card/received`,
        {    
          headers: {
              'Authorization': 'Bearer ' + getToken()
          }
      }
      );
};
function updateProfile(body){
    return axios.post(
        baseUrl +`/v1/exhibitor/update`,
        body,
        {    
          headers: {
              'Authorization': 'Bearer ' + getToken()
          }
      }
      
      );
};
function sendEmail(body){
    return axios.post(
        baseUrl +`/v1/card/all/send-email`,
        body,
        {    
          headers: {
              'Authorization': 'Bearer ' + getToken()
          }
      }
      
      );
};
export { getExhibitorDetails, getDashboardDetails, updateProfile, sendEmail };