import { baseUrl, getToken } from './constants';
import axios from 'axios';

 function getCards(){
    return axios.get(baseUrl + '/v1/card/all',
    {    
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

 function createCard(card){
    return axios.post(baseUrl + '/v1/card/create',card,
    {    
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}
 function exchangeCard(cardID,exhibitorID){
    return axios.post(baseUrl + `/v1/card/${cardID}/exhibitor/${exhibitorID}`,{},
    {    
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}
function unsharedExhibitors(){
    let exhibitors = JSON.parse(localStorage.getItem('exhibitors') || `{}`);
    const keysWithZeros = Object.keys(exhibitors).filter(key => exhibitors[key] === 0);
    return keysWithZeros;
}
function removeExhibitorFromList(id){
    let exhibitors = JSON.parse(localStorage.getItem('exhibitors') || `{}`);
    exhibitors[id] = 1;
    localStorage.setItem('exhibitors', JSON.stringify(exhibitors));
}
export { getCards, createCard, exchangeCard, unsharedExhibitors, removeExhibitorFromList };