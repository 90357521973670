import axios from 'axios';
import { baseUrl } from './constants'
export async function register(email, contact, name) {
  const body = {
    email,
    contact,
    name,
    exhibition_id: 2,
  };

  try{
    const res = await axios.post(
      baseUrl +'/v1/visitor/register',
      body
    );
    if (res.status === 201) {
      window.location.href = res.data.token;
      return res;
    }
  }
  catch(err){
    console.log(err);
  }
}
